import { useEffect } from "react";

import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";

import getRoutes from "app/utils/getRoutes";

import { routes } from "routes";

import useConsent from "app/hooks/useConsent";
import setConsent from "app/utils/consent/setConsent";

import { GA_TRACKING_ID } from "settings/ga";
import gtag from "app/ga";

const AppRoute = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [consent] = useConsent();

  const updateGtagConfig = () => {
    const queryStr = searchParams.toString();
    if (queryStr) {
      gtag("config", GA_TRACKING_ID, {
        page_path: pathname,
        queries: queryStr,
      });
    } else {
      gtag("config", GA_TRACKING_ID, {
        page_path: pathname,
      });
    }
  };


  useEffect(() => {
    if (consent.necessary) {
      setConsent(consent.performance);
      updateGtagConfig();
    }
  }, [consent]);

  useEffect(updateGtagConfig, [pathname, searchParams]);

  useEffect(() => {
    document.title = "หารือ.คอม";
  }, [pathname]);

  return <Routes>{getRoutes(routes)}</Routes>;
};

export default AppRoute;
