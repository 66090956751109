import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import AskQuestionForm from "components/AskQuestionForm";
import MainLayout from "layouts/MainLayout";

import { SIGN_IN_PATH, ASK_QUESTION_PATH } from "settings/path";

const AskPage = ({ isSignIn }) => {
  if (isSignIn) {
    return (
      <MainLayout>
        <AskQuestionForm p={3} />
      </MainLayout>
    );
  } else {
    return <Navigate to={`${SIGN_IN_PATH}?redirect_to=${ASK_QUESTION_PATH}`} />;
  }
};

const mapStateToProps = (state) => {
  return { isSignIn: state.app.isSignIn };
};

export default connect(mapStateToProps)(AskPage);
