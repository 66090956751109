import { useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";
import SoftBox from "softcomponents/SoftBox";
import SoftButton from "softcomponents/SoftButton";

const AskQuestionButton = () => {
  const navigate = useNavigate();

  return (
    <SoftBox
      onClick={() => {
        navigate("/ask");
      }}
    >
      <SoftBox display={{ xs: "inherit", md: "none" }}>
        <IconButton size="medium" color="info">
          <Icon>live_help</Icon>
        </IconButton>
      </SoftBox>
      <SoftBox display={{ xs: "none", md: "inherit" }}>
        <SoftButton variant="gradient" color="info" size="small" circular>
          <Icon>live_help</Icon>
          &nbsp;&nbsp;ปรึกษาหารือ
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
};

export default AskQuestionButton;
