import { Fragment, useEffect, useState } from "react";

// Soft UI Dashboard React components
import SoftBox from "softcomponents/SoftBox";
import SoftPagination from "softcomponents/SoftPagination";
import SoftSelect from "softcomponents/SoftSelect";

// @mui material components
import Icon from "@mui/material/Icon";
import { useSearchParams } from "react-router-dom";
import { PAGE_SIZE } from "settings/forum";
import SoftTypography from "softcomponents/SoftTypography";

const Pagination = ({ count }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(20);
  const [pageSize, setPageSize] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    if (currentPage !== searchParams.get("page")) {
      setCurrentPage(parseInt(searchParams.get("page")) || 1);
    }
    if (pageSize !== searchParams.get("page_size")) {
      setPageSize(parseInt(searchParams.get("page_size")) || 25);
    }
  }, [searchParams]);

  useEffect(() => {
    setLastPage(Math.floor(count / pageSize) + 1);
  }, [pageSize, count]);

  const handlePageSizeChange = (e) => {
    setSearchParams({ ...searchParams, page_size: e.value });
  };

  const goToPage = (page) => {
    setSearchParams({ ...searchParams, page });
  };

  const PageItem = ({ page }) => {
    return (
      <SoftPagination
        item
        onClick={() => {
          goToPage(page);
        }}
      >
        {page}
      </SoftPagination>
    );
  };

  const beforeCurrent = () => {
    const before = [];
    for (let i = 2; i > 0; i--) {
      before.push(currentPage - i);
    }
    return (
      <Fragment>
        {before
          .filter((i) => i > 0)
          .map((p) => {
            return <PageItem page={p} key={`go_to_page${p}`} />;
          })}
      </Fragment>
    );
  };

  const afterCurrent = () => {
    const after = [];
    for (let i = 1; i <= 2; i++) {
      after.push(currentPage + i);
    }

    return (
      <Fragment>
        {after
          .filter((i) => i <= lastPage)
          .map((p) => {
            return <PageItem page={p} key={`go_to_page${p}`} />;
          })}
      </Fragment>
    );
  };

  return (
    <SoftBox
      p={2}
      display="flex"
      justifyContent="space-between"
      sx={{ userSelect: "none" }}
    >
      <SoftBox display="flex" flexDirection="row" alignItems="center">
        <SoftTypography variant="caption" mr={1}>
          แสดง
        </SoftTypography>
        <SoftSelect
          size="small"
          placeholder="จำนวนต่อหน้า"
          value={PAGE_SIZE.find((e) => e.value == pageSize)}
          options={PAGE_SIZE}
          onChange={handlePageSizeChange}
        />
        <SoftTypography variant="caption" ml={1}>
          จากทั้งหมด {count} รายการ
        </SoftTypography>
      </SoftBox>
      {lastPage !== 1 && (
        <SoftPagination size="small">
          {currentPage !== 1 && (
            <SoftPagination
              item
              onClick={() => {
                goToPage(1);
              }}
            >
              <Icon>keyboard_double_arrow_left</Icon>
            </SoftPagination>
          )}
          {beforeCurrent()}
          <SoftPagination item active>
            {currentPage}
          </SoftPagination>
          {afterCurrent()}
          {currentPage < lastPage && (
            <SoftPagination
              item
              onClick={() => {
                goToPage(lastPage);
              }}
            >
              <Icon>keyboard_double_arrow_right</Icon>
            </SoftPagination>
          )}
        </SoftPagination>
      )}
    </SoftBox>
  );
};

export default Pagination;
