import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SoftAvatar from "softcomponents/SoftAvatar";

const UserMenu = ({ email }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton onClick={handleClick}>
        <Icon>account_circle</Icon>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>ยินดีต้อนรับ {email}</MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/sign-out");
          }}
        >
          ออกจากระบบ
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return { email: state.user.email };
};

export default connect(mapStateToProps)(UserMenu);
