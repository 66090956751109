import { createStore, applyMiddleware } from "redux";
// import { compose } from "redux";
import reducers from "reducers";
import reduxThunk from "redux-thunk";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  applyMiddleware(reduxThunk)
  // composeEnhancers(applyMiddleware(reduxThunk))
);

export default store;
