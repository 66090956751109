import { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import { Divider, Grid } from "@mui/material";
import { Container } from "@mui/system";
import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";

const FooterLink = ({ text, link }) => {
  const navigate = useNavigate();

  return (
    <SoftBox
      sx={{ cursor: "pointer", userSelect: "none" }}
      onClick={() => {
        navigate(link);
      }}
    >
      <SoftTypography variant="caption">ปรึกษาหารือ</SoftTypography>
    </SoftBox>
  );
};

const Footer = () => {
  const footerRef = useRef();
  const autoSpaceRef = useRef();
  const { pathname } = useLocation();

  const [autoSpace, setAutoSpace] = useState(0);

  const autoCdxFooter = () => {
    if (!footerRef.current) {
      return;
    }

    const body = document.body;
    const html = document.documentElement;

    const footerPos = footerRef.current.getBoundingClientRect();
    const autoSpaceHeight = autoSpaceRef.current.getBoundingClientRect().height;

    const bodyHeight = Math.max(body.scrollHeight, body.offsetHeight);

    const rawBodyHeight = bodyHeight - autoSpaceHeight;

    const windowHeight = html.clientHeight;

    if (rawBodyHeight > windowHeight - 0) {
      if (autoSpaceHeight === 0) {
        return;
      } else {
        setAutoSpace(0);
      }
      return;
    }

    if (footerPos.bottom === windowHeight - 0) {
    } else if (footerPos.bottom < windowHeight - 0) {
      setAutoSpace(autoSpaceHeight + (windowHeight - 0 - footerPos.bottom));
    } else if (footerPos.bottom - autoSpaceHeight < windowHeight - 0) {
      setAutoSpace(autoSpaceHeight + (windowHeight - 24 - footerPos.bottom));
    }
  };

  const footerTimeout = () => {
    setTimeout(autoCdxFooter, 200);
  };

  useEffect(() => {
    window.addEventListener("resize", footerTimeout);
    document.body.addEventListener("click", footerTimeout);
    document.body.addEventListener("keypress", footerTimeout);
    footerTimeout();

    return () => {
      window.removeEventListener("resize", footerTimeout);
      document.body.removeEventListener("click", footerTimeout);
      document.body.removeEventListener("keypress", footerTimeout);
    };
  }, []);

  useEffect(() => {
    footerTimeout();
  }, [pathname]);

  const navigate = useNavigate();

  return (
    <Fragment>
      <SoftBox ref={autoSpaceRef} sx={{ height: autoSpace }} />
      <SoftBox
        ref={footerRef}
        widht="100%"
        mt={2.5}
        sx={{ userSelect: "none" }}
      >
        <Divider />
        <Container>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            py={5}
          >
            <Grid container item xs={12} md={10} lg={11}>
              <Grid item xs={12}>
                <SoftTypography variant="button" fontWeight="bold">
                  สงวนลิขสิทธิ์ 2565.
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="regular">
                  {" "}
                  สามารถนำไปอ้างอิงได้
                  อย่างไรก็ตามท่านต้องใช้วิจารณญาณในการนำไปใช้.{" "}
                  ปัจจุบันอยู่ในระหว่างการทดสอบระบบ (Version b1.0).{" "}
                </SoftTypography>
              </Grid>
              <Grid item xs={12}>
                <SoftTypography variant="caption" fontWeight="regular">
                  ท่านสามารถศึกษา
                </SoftTypography>
                <NavLink to="/privacy">
                  <SoftTypography
                    variant="caption"
                    fontWeight="bold"
                    sx={{
                      "&:hover": {
                        color: ({ palette: { info } }) => info.main,
                      },
                    }}
                  >
                    นโยบายคุ้มครองข้อมูลส่วนบุคคล
                  </SoftTypography>
                </NavLink>
                <SoftTypography variant="caption" fontWeight="regular">
                  ได้ที่นี่
                </SoftTypography>
              </Grid>
            </Grid>
            <Grid item>
              <SoftTypography
                variant="h6"
                component="div"
                sx={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                หารือ.คอม
              </SoftTypography>
            </Grid>
          </Grid>
        </Container>
      </SoftBox>
    </Fragment>
  );
};

export default Footer;
