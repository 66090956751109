import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import UserAPI from "app/api/user";
import QuestionAnswerTemplate from "components/QuestionAnswer";
import { connect } from "react-redux";
import { updateAnswerSearchParams } from "actions";

import checkObjectEqual from "app/utils/checkObjectEqual";
import MainLayout from "layouts/MainLayout";

const QuestionPage = ({ saveSearchParams, updateSearchParams, isSignIn }) => {
  const [questionAnswerData, setQuestionAnswerData] = useState({});
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const load = async (code, params = {}) => {
    if (code) {
      const result = await UserAPI().get(`/questions/${code}`, { params });
      setQuestionAnswerData(result.data.data);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const question_code = pathname.split("/").at(2);
    const parse_params = Object.fromEntries([...searchParams]);

    var params = { ...parse_params };
    if (searchParams.get("clear") === 1) {
      delete params.clear;
      setSearchParams(params);
      return;
    } else if (Object.keys(parse_params).length > 0) {
      const combineParams = { ...saveSearchParams, ...params };
      if (checkObjectEqual(params, combineParams)) {
        updateSearchParams(combineParams);
        load(question_code, params);
      } else {
        updateSearchParams(combineParams);
        setSearchParams(combineParams);
      }
    } else {
      load(question_code);
    }
  }, [pathname, searchParams, isSignIn]);

  return (
    <MainLayout>
      <QuestionAnswerTemplate data={questionAnswerData} />
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    saveSearchParams: state.app.answerSearchParams,
    isSignIn: state.app.isSignIn,
  };
};

const mapDispatchToProps = {
  updateSearchParams: updateAnswerSearchParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
