//https://datayolk.net/

import { useState, useEffect, Fragment } from "react";
import { Grid, Container, Icon } from "@mui/material";

import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";
import SoftButton from "softcomponents/SoftButton";

import rgba from "assets/theme/functions/rgba";

import { NavLink, useLocation } from "react-router-dom";

import { PATH_TO_SKIP_PRIVACY_CONSENT } from "settings";
import CookieConsent from "layouts/PrivacyConsent/CookieConsent";
import getConsent from "app/utils/consent/getConsent";
import setConsent from "app/utils/consent/setConsent";

const PrivacyConsent = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(true);
  const [openConsentDialog, setOpenConsentDialog] = useState(false);

  useEffect(() => {
    const isStartsWithSkipPath =
      PATH_TO_SKIP_PRIVACY_CONSENT.filter((e) => pathname.startsWith(e))
        .length > 0;
    if (isStartsWithSkipPath) {
      setShow(false);
    } else {
      const consent = getConsent();
      if (consent.necessary) {
        setShow(false);
      } else {
        setShow(!openConsentDialog);
      }
    }
  }, [pathname, openConsentDialog]);

  const acceptAllConsent = () => {
    setConsent(true);
    setShow(false);
  };

  const openPrivacyConsent = () => {
    setOpenConsentDialog(true);
  };

  return (
    <Fragment>
      {openConsentDialog && <CookieConsent setOpen={setOpenConsentDialog} />}
      {show ? (
        <Fragment>
          <SoftBox
            bgColor={rgba("#000000", 0.7)}
            sx={{
              height: "100vh",
              width: "100vw",
              position: "sticky",
              bottom: 0,
              zIndex: 1501,
            }}
          />
          <SoftBox
            bgColor="dark"
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1501,
            }}
          >
            <Container>
              <SoftBox minHeight="20vh" py={4}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} md>
                    <SoftBox lineHeight={1}>
                      <SoftTypography color="white" variant="h5" lineHeight={3}>
                        {"เราให้คุณค่ากับความเป็นส่วนตัวของคุณ"}
                      </SoftTypography>
                      <SoftBox component="p" py={0.5}>
                        <SoftTypography color="white" variant="caption">
                          {
                            "เราเก็บและ/หรือเข้าถึงข้อมูลของอุปกรณ์ อาทิ คุกกี้ และประมวลข้อมูลส่วนบุคคล เช่น ข้อมูลระบุเอกลักษณ์ (unique indentifiers) และข้อมูลทั่วไปที่ถูกส่งโดยอุปกรณ์ เป็นต้น เพื่อการพัฒนาและปรับปรุงเว็บไซต์"
                          }
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox component="p" py={0.5}>
                        <SoftTypography color="white" variant="caption">
                          {"เรียนรู้เพิ่มเติมเกี่ยวกับ"}{" "}
                        </SoftTypography>
                        <NavLink to="/privacy">
                          <SoftTypography
                            color="white"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {"นโยบายคุ้มครองข้อมูลส่วนบุคคล"}
                          </SoftTypography>
                        </NavLink>
                        <SoftTypography color="white" variant="caption">
                          {" "}
                          {"และ"}{" "}
                        </SoftTypography>
                        <NavLink to="/privacy/cookies">
                          <SoftTypography
                            color="white"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {"นโยบายคุกกี้"}
                          </SoftTypography>
                        </NavLink>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <SoftBox>
                      <Grid
                        container
                        direction={{ xs: "row", md: "column" }}
                        spacing={2}
                        justifyContent="space-between"
                        alignItems={{ xs: "center", md: "stretch" }}
                      >
                        <Grid item md={12}>
                          <SoftButton
                            variant="gradient"
                            color="warning"
                            onClick={acceptAllConsent}
                            fullWidth
                          >
                            {"ฉันเข้าใจและยอมรับคุกกี้ทั้งหมด"}
                          </SoftButton>
                        </Grid>
                        <Grid item md={12}>
                          <SoftButton
                            variant="outlined"
                            color="light"
                            fullWidth
                            onClick={openPrivacyConsent}
                          >
                            {"การตั้งค่าแบบกำหนดเอง"}
                          </SoftButton>
                        </Grid>
                        <Grid
                          container
                          item
                          md={12}
                          justifyContent={{ xs: "flex-start", md: "flex-end" }}
                        ></Grid>
                      </Grid>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </Container>
          </SoftBox>
        </Fragment>
      ) : (
        <Fragment>
          <SoftBox
            sx={{
              position: "fixed",
              right: "1vh",
              bottom: "1vh",
              zIndex: 20,
            }}
          >
            <SoftButton
              variant="gradient"
              color="secondary"
              iconOnly
              circular
              size="large"
              shadow="lg"
              onClick={openPrivacyConsent}
            >
              <Icon>cookie</Icon>
            </SoftButton>
          </SoftBox>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PrivacyConsent;
