import SoftBadge from "softcomponents/SoftBadge";

const QuestionScenario = ({ scenario }) => {
  return (
    <SoftBadge
      badgeContent={`${scenario.key} # ${scenario.value}`}
      variant="gradient"
      color="primary"
      circular
      style={{ userSelect: "none" }}
    />
  );
};

export default QuestionScenario;
