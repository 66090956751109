import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Custom styles for the SnackBar
import SnackbarIconRoot from "components/Snackbars/Snackbar/SnackbarIconRoot";

import Slide from "@mui/material/Slide";

import { connect } from "react-redux";
import { useState, useEffect } from "react";

import { destroySnackbar } from "actions/app";

function SnackBar({
  color,
  icon,
  title,
  timestamp,
  content,
  close,
  bgWhite,
  uuid,
  timeout = 1000 * 10,
  destroySnackbar,
  ...rest
}) {
  const { size } = typography;
  let titleColor;
  let dateTimeColor;
  let dividerColor;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = "dark";
    dividerColor = false;
  } else if (color === "light") {
    titleColor = "dark";
    dateTimeColor = "text";
    dividerColor = false;
  } else {
    titleColor = "white";
    dateTimeColor = "white";
    dividerColor = true;
  }

  const [dateTime, setDateTime] = useState("just now");

  const handleClose = () => {
    destroySnackbar(uuid);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      destroySnackbar(uuid);
    }, timestamp + timeout - Date.now());

    return () => {
      clearTimeout(timeOutId);
    };
  }, [destroySnackbar, uuid, timestamp, timeout]);

  useEffect(() => {
    const renderTime = () => {
      const now = Date.now();
      const timePass = (now - timestamp) / 1000;

      if (timePass + 1 < 60) {
        return {
          text: `${parseInt(timePass + 1)} ${"วินาทีที่แล้ว"}`,
          next: timestamp + parseInt(timePass + 1) * 1000 - now + 200,
        };
      } else {
        return {
          text: `${parseInt(timePass / 60 + 1)} ${"นาทีที่แล้ว"}`,
          next: timestamp + parseInt(timePass / 60 + 1) * 60 * 1000 - now + 200,
        };
      }
    };

    const time = renderTime();
    const timeOutId = setTimeout(() => {
      setDateTime(time.text);
    }, time.next);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [dateTime, timestamp]);

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <SoftBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          p={1.5}
        >
          <SoftBox display="flex" alignItems="center" lineHeight={0}>
            <SnackbarIconRoot fontSize="small" ownerState={{ color, bgWhite }}>
              {icon}
            </SnackbarIconRoot>
            <SoftTypography
              variant="button"
              fontWeight="medium"
              color={titleColor}
              textGradient={bgWhite}
            >
              {title}
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" alignItems="center" lineHeight={0} pl={1}>
            <SoftTypography variant="caption" color={dateTimeColor}>
              {dateTime}
            </SoftTypography>
            <Icon
              sx={{
                color: ({ palette: { dark, white } }) =>
                  bgWhite || color === "light" ? dark.main : white.main,
                fontWeight: ({ typography: { fontWeightBold } }) =>
                  fontWeightBold,
                cursor: "pointer",
                marginLeft: 2,
                transform: "translateY(-1px)",
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </SoftBox>
        </SoftBox>
        <Divider sx={{ margin: 0 }} light={dividerColor} />
        <SoftBox
          p={1.5}
          color={bgWhite || color === "light" ? "text" : "white"}
          fontSize={size.sm}
        >
          {content}
        </SoftBox>
      </SoftBox>
    </Slide>
  );
}

// Setting default values for the props of SnackBar
SnackBar.defaultProps = {
  bgWhite: false,
  color: "info",
};

// Typechecking props for SnackBar
SnackBar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  timestamp: PropTypes.number,
  content: PropTypes.node.isRequired,
  bgWhite: PropTypes.bool,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = { destroySnackbar };
export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
