import {
  SIGN_IN,
  SIGN_OUT,
  UPDATE_CSRF_TOKEN,
  UPDATE_QUESTION_SEARCH_PARAMS,
  UPDATE_ANSWER_SEARCH_PARAMS,
  DEPLOY_SNACKBAR,
  UPDATE_SNACKBAR,
} from "actions/types";

const appDefault = {
  answerSearchParams: {},
  questionSearchParams: {},
  isSignIn: false,
  snackBars: [],
  csrfToken: "",
};

const appReducer = (app = appDefault, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...app, isSignIn: true };
    case SIGN_OUT:
      return { ...app, isSignIn: false };
    case UPDATE_CSRF_TOKEN:
      return { ...app, csrfToken: action.payload.csrfToken };
    case DEPLOY_SNACKBAR:
      return {
        ...app,
        snackBars: [...app.snackBars, action.payload.newSnackBar],
      };
    case UPDATE_SNACKBAR:
      return {
        ...app,
        snackBars: action.payload.newSnackBars,
      };
    case UPDATE_QUESTION_SEARCH_PARAMS:
      return { ...app, questionSearchParams: action.payload };
    case UPDATE_ANSWER_SEARCH_PARAMS:
      return { ...app, answerSearchParams: action.payload };
    default:
      return app;
  }
};

export default appReducer;
