import { useEffect, useState } from "react";

import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";

import showdown from "showdown";
import { load as cheerioLoad } from "cheerio";

const QuestionBoxCaption = ({ body }) => {
  const [caption, setCaption] = useState(body);

  useEffect(() => {
    if (body) {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(body);
      const text = cheerioLoad(html).text();

      setCaption(text);
    } else {
      setCaption("");
    }
  }, [body]);

  return (
    <SoftBox>
      <SoftTypography
        variant="caption"
        component="p"
        style={{ lineHeight: "1.25rem" }}
      >
        {caption}
        {body.length >= 300 && "..."}
      </SoftTypography>
    </SoftBox>
  );
};

export default QuestionBoxCaption;
