// Soft UI Dashboard PRO React components
import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";
import { Divider } from "@mui/material";

import VoteButton from "components/VoteButton";
import MarkDownHtml from "components/MarkDownHtml";
import CreateBy from "components/CreateBy";
import QuestionTag from "components/QuestionTag";
import QuestionScenario from "components/QuestionScenario";

const PostBox = ({ post = {} }) => {
  return (
    <SoftBox
      sx={{ px: 1.75, py: 1, mt: 2 }}
      display="flex"
      flexDirection="column"
    >
      <SoftBox display="flex" sx={{ flexGrow: 1 }}>
        <VoteButton
          vote={post.vote_net}
          post_code={post.post_code}
          user_vote={post.my_vote}
        />
        <SoftBox sx={{ flexGrow: 1 }}>
          <SoftBox px={2}>
            {post.title && (
              <SoftBox mb={2}>
                <SoftTypography variant="h5" component="h1">
                  {post.title}
                </SoftTypography>
              </SoftBox>
            )}
            <SoftBox mb={2}>{<MarkDownHtml md={post.body} />}</SoftBox>
          </SoftBox>
          {/* <Divider /> */}
        </SoftBox>
      </SoftBox>
      <SoftBox lineHeight="1.5rem">
        {post.tags &&
          post.tags.map((t) => {
            return <QuestionTag key={post.post_code + t} tag={t} />;
          })}
        {post.scenarios &&
          post.scenarios.map((scene) => {
            return (
              <QuestionScenario
                scenario={scene}
                key={post.post_code + scene.key + scene.value}
              />
            );
          })}
      </SoftBox>
      <SoftBox mb={2}>
        <CreateBy
          display_name={
            post.created_display_name || post.created_anonymous_name
          }
          created_at={post.created_at}
        />
      </SoftBox>
      <Divider />
    </SoftBox>
  );
};

export default PostBox;
