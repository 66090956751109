import { connect } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

import MainLayout from "layouts/MainLayout";
import SignUpForm from "components/SignUpForm";
import SoftBox from "softcomponents/SoftBox";

import { DEFAULT_PATH } from "settings/path";

const SignIn = ({ isSignIn }) => {
  const [searchParams] = useSearchParams();

  if (!isSignIn) {
    return (
      <MainLayout size="sm">
        <SoftBox mt={3}>
          <SignUpForm />
        </SoftBox>
      </MainLayout>
    );
  } else {
    return <Navigate to={searchParams.get("redirect_to") || DEFAULT_PATH} />;
  }
};

const mapStateToProps = (state) => {
  return { isSignIn: state.app.isSignIn };
};

export default connect(mapStateToProps)(SignIn);
