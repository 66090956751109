import { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import { Grid, Switch, Divider, Icon } from "@mui/material";

import store from "store";
import { deploySnackbar } from "actions";
import { deployErrorSnackbar } from "actions/snackBar";

import UserAPI from "app/api/user";

import SoftBox from "softcomponents/SoftBox";
import SoftButton from "softcomponents/SoftButton";
import SoftInput from "softcomponents/SoftInput";
import SoftTypography from "softcomponents/SoftTypography";

import { FORGET_PASSWORD_PATH, SIGN_IN_PATH } from "settings/path";

const SignUpForm = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [vEmail, setVEmail] = useState(false);
  const [vDisplay, setVDisplay] = useState(false);
  const [vPassword, setVPassword] = useState(false);
  const [vPassword2, setVPassword2] = useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    setVEmail(false);
    if (email) {
      const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      };
      if (validateEmail(email)) {
        setVEmail(true);
      }
    }
  }, [email]);

  useEffect(() => {
    setVPassword(false);
    setVPassword2(false);

    if (password.length < 8) {
    } else if (password.search(/[A-Z]/) < 0) {
    } else if (password.search(/[a-z]/) < 0) {
    } else if (password.search(/\d/) < 0) {
    } else if (password.search(/[!@#$%^&*()+-/.]/) < 0) {
    } else {
      setVPassword(true);
    }
    if (password2 === password && password2.length > 0) {
      setVPassword2(true);
    }
  }, [password, password2]);

  const checkDisplayName = async () => {
    setVDisplay(false);
    if (displayName.length > 5) {
      try {
        await UserAPI().get(`/user/${displayName}`);
      } catch (error) {
        if (error.response.data.message.code === "0xbc277e82") {
          setVDisplay(true);
        }
      }
    }
  };

  const submitCreateAccount = async () => {
    const data = {
      email,
      display_name: displayName,
      password,
    };
    try {
      const result = await UserAPI().post("accounts/sign-up", data);

      if (result.status === 201) {
        setTimeout(() => {
          navigate(SIGN_IN_PATH);
        }, 1000 * 5);
        const dispatch = store.dispatch;
        const snackBar = {
          color: "success",
          icon: "done",
          title: "สร้างบัญชีผู้ใช้สำเรีจ",
          content: `ระบบจะนำท่านไปหน้าเข้าสู่ระบบโดยอัตโนมัติ`,
        };
        dispatch(deploySnackbar(snackBar));
      }
    } catch (error) {
      deployErrorSnackbar(error);
    }
  };

  return (
    <Grid
      container
      width="100%"
      justifyContent="center"
      spacing={2}
      sx={{ userSelect: "none" }}
    >
      <Grid item xs={12} maxWidth="300px" textAlign="center">
        <SoftTypography variant="h5" component="h1">
          สมัครสมาชิก
        </SoftTypography>
      </Grid>
      <Grid item xs={12} maxWidth="300px">
        <SoftInput
          placeholder="ชื่อผู้ใช้"
          value={displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
          onBlur={checkDisplayName}
        />
      </Grid>
      <Grid item xs={12} maxWidth="300px">
        <SoftInput
          placeholder="อีเมล"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} maxWidth="300px">
        <SoftInput
          placeholder="รหัสผ่าน"
          type={showPass ? "input" : "password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} maxWidth="300px">
        <SoftInput
          placeholder="ยืนยันรหัสผ่าน"
          type={showPass ? "input" : "password"}
          value={password2}
          onChange={(e) => {
            setPassword2(e.target.value);
          }}
        />
      </Grid>
      <Grid container item xs={12} maxWidth="300px" columnSpacing={2}>
        <Grid item>
          <Switch
            color="default"
            checked={showPass}
            onChange={(e) => {
              setShowPass(!showPass);
            }}
          />
        </Grid>
        <Grid item>
          <SoftTypography variant="button">
            {showPass ? "แสดงรหัสผ่าน" : "ซ่อนรหัสผ่าน"}
          </SoftTypography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vDisplay ? "success" : "error"}>
            {vDisplay ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vDisplay ? "dark" : "error"}
          >{`ชื่อแสดงผลผู้ใช้ไม่ซ้ำ (ขั้นต่ำ 6 ตัวอักษร)`}</SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vEmail ? "success" : "error"}>
            {vEmail ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vEmail ? "dark" : "error"}
          >{`โปรดระบุอีเมล`}</SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vPassword ? "success" : "error"}>
            {vPassword ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vPassword ? "dark" : "error"}
          >{`ระบุรหัสผ่านขั้นต่ำ 8 ตัวอักษรและประกอบด้วย ภาษาอังกฤษตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก เลขอาราบิก และ เครื่องหมายพิเศษ (!@#$%^&*()+-/.)`}</SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vPassword2 ? "success" : "error"}>
            {vPassword2 ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vPassword2 ? "dark" : "error"}
          >{`โปรดยืนยันรหัสผ่านให้เหมือนกัน`}</SoftTypography>
        </SoftBox>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item>
          <SoftButton
            size="small"
            color="warning"
            variant="gradient"
            disabled={!(vEmail && vPassword && vPassword2 && vDisplay)}
            onClick={submitCreateAccount}
          >
            สมัครสมาชิก
          </SoftButton>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" columnSpacing={4}>
        <Grid item>
          <NavLink to={FORGET_PASSWORD_PATH}>
            <SoftTypography variant="caption">ลืมรหัสผ่าน</SoftTypography>
          </NavLink>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <NavLink to={SIGN_IN_PATH}>
            <SoftTypography variant="caption">ลงชื่อเข้าใช้</SoftTypography>
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpForm;
