import { useState, useEffect } from "react";
import SoftBox from "softcomponents/SoftBox";
import { Grid, Icon } from "@mui/material";
import SoftTypography from "softcomponents/SoftTypography";

const AskQuestionVerify = ({ title, body, setVerify }) => {
  const [vTitle, setVTitle] = useState(false);
  const [vBody, setVBody] = useState(false);

  useEffect(() => {
    if ((title.length >= 10) & (title.length <= 256)) {
      setVTitle(true);
    } else {
      setVTitle(false);
    }
  }, [title]);

  useEffect(() => {
    if (body.length >= 20) {
      setVBody(true);
    } else {
      setVBody(false);
    }
  }, [body]);

  useEffect(() => {
    setVerify(vTitle & vBody);
  }, [vTitle, vBody]);

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vTitle ? "success" : "error"}>
            {vTitle ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vTitle ? "dark" : "error"}
          >{`ชื่อหัวข้อต้องมีความยาวระหว่าง 10 ถึง 256 ตัวอักษร (ปัจจุบัน ${title.length} อักษร)`}</SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vBody ? "success" : "error"}>
            {vBody ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vBody ? "dark" : "error"}
          >{`เนื้อหาต้องมีความยาวอย่างน้อย 20 ตัวอักษร (ปัจจุบัน ${body.length} อักษร)`}</SoftTypography>
        </SoftBox>
      </Grid>
    </Grid>
  );
};

export default AskQuestionVerify;
