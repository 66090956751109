import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import onAppFirstLoad from "app/onAppFirstLoad";
import AppBar from "components/AppBar";
import Footer from "components/Footer";

import Snackbars from "components/Snackbars";

import PrivacyConsent from "layouts/PrivacyConsent";

import AppRoute from "AppRoute";

const App = () => {
  useEffect(() => {
    onAppFirstLoad();
  }, []);

  return (
    <BrowserRouter>
      <AppBar />
      <AppRoute />
      <Footer />
      <Snackbars />
      <PrivacyConsent />
    </BrowserRouter>
  );
};

export default App;
