import parse, { domToReact } from "html-react-parser";
import SoftTypography from "softcomponents/SoftTypography";
import { useEffect, useState, Fragment } from "react";
import showdown from "showdown";

const MarkDownHtml = ({ md }) => {
  const [html, setHtml] = useState(<Fragment />);

  useEffect(() => {
    if (md) {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(md);
      const renderHtml = parse(html, {
        replace: ({ name, children }) => {
          if (name === "p") {
            return (
              <SoftTypography
                variant="body2"
                component="p"
                style={{ lineHeight: "1.75rem" }}
                mb={1.25}
              >
                {domToReact(children)}
              </SoftTypography>
            );
          } else if (name === "h1") {
            return (
              <SoftTypography variant="h4" component="h1">
                {domToReact(children)}
              </SoftTypography>
            );
          } else if (name === "h2") {
            return (
              <SoftTypography variant="h5" component="h2">
                {domToReact(children)}
              </SoftTypography>
            );
          } else if (name === "h3") {
            return (
              <SoftTypography variant="h5" component="h3">
                {domToReact(children)}
              </SoftTypography>
            );
          } else if (name === "h4") {
            return (
              <SoftTypography variant="h5" component="h4">
                {domToReact(children)}
              </SoftTypography>
            );
          } else if (name === "h5") {
            return (
              <SoftTypography variant="h5" component="h5">
                {domToReact(children)}
              </SoftTypography>
            );
          } else if (name === "h6") {
            return (
              <SoftTypography variant="h6" component="h6">
                {domToReact(children)}
              </SoftTypography>
            );
          }
        },
      });

      setHtml(renderHtml);
    } else {
      setHtml("");
    }
  }, [md]);

  return <Fragment>{html}</Fragment>;
};

export default MarkDownHtml;
