export const FORUM_TAGS = [
  { value: "ภาษีเงินได้บุคคลธรรมดา", label: "ภาษีเงินได้บุคคลธรรมดา" },
  { value: "ภาษีเงินได้นิติบุคคล", label: "ภาษีเงินได้นิติบุคคล" },
  { value: "ภาษีมูลค่าเพิ่ม", label: "ภาษีมูลค่าเพิ่ม" },
  { value: "ภาษีเงินได้ หัก ณ ที่จ่าย", label: "ภาษีเงินได้ หัก ณ ที่จ่าย" },
  { value: "กฎหมายธุรกิจ", label: "กฎหมายธุรกิจ" },
  { value: "กฎหมายแรงงาน", label: "กฎหมายแรงงาน" },
  { value: "ประกันสังคม", label: "ประกันสังคม" },
  { value: "บัญชีทั่วไป", label: "บัญชีทั่วไป" },
  { value: "บัญชี NPAE", label: "บัญชี NPAE" },
  { value: "บัญชี TFRS", label: "บัญชี TFRS" },
];

export const PAGE_SIZE = [
  { label: "5 รายการ", value: 5 },
  { label: "10 รายการ", value: 10 },
  { label: "25 รายการ", value: 25 },
  { label: "50 รายการ", value: 50 },
  { label: "100 รายการ", value: 100 },
];
