import { auth } from "./config";

import { onAuthStateChanged } from "firebase/auth";

import store from "store";
import { signIn, signOut } from "actions";

const authState = () => {
  const dispatch = store.dispatch;

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
      dispatch(signIn(user));
    } else {
      // User is not signed in
      dispatch(signOut());
    }
  });
};

export default authState;
