import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SoftInput from "softcomponents/SoftInput";

const AppBarSearchInput = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.startsWith("questions?")) {
      setValue("");
    }
  }, [pathname]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/questions?text=${event.target.value}`);
    }
  };

  return (
    <SoftInput
      width="100%"
      placeholder="ค้นหา..."
      size="small"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      icon={{
        component: "search",
        direction: "left",
      }}
      onKeyDown={handleKeyDown}
    />
  );
};

export default AppBarSearchInput;
