import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import { Grid, Switch, Divider, Icon } from "@mui/material";

import { deployErrorSnackbar } from "actions/snackBar";

import signIn from "app/fb/signIn";

import SoftBox from "softcomponents/SoftBox";
import SoftButton from "softcomponents/SoftButton";
import SoftInput from "softcomponents/SoftInput";
import SoftTypography from "softcomponents/SoftTypography";

import { SIGN_UP_PATH } from "settings/path";
import { FORGET_PASSWORD_PATH } from "settings/path";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [vEmail, setVEmail] = useState(false);
  const [vPassword, setVPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    setVEmail(false);
    if (email) {
      const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      };
      if (validateEmail(email)) {
        setVEmail(true);
      }
    }
  }, [email]);

  useEffect(() => {
    setVPassword(false);
    if (password.length > 0) {
      setVPassword(true);
    }
  }, [password]);

  const submitSignIn = async () => {
    const result = await signIn(email, password);
    if (result.type === "success") {
    } else if (result.type === "error") {
      const message = {
        response: {
          data: { message: { code: result.data.code, text: "" } },
        },
      };
      if (result.data.code === "auth/user-not-found") {
        message.response.data.message.text = "Couldn't find your email";
      } else if (result.data.code === "auth/wrong-password") {
        message.response.data.message.text = "Your password is incorrect";
      } else {
        message.response.data.message.text =
          "Unable to sign in, please try again";
      }
      deployErrorSnackbar(message);
    }
  };

  return (
    <Grid
      container
      width="100%"
      justifyContent="center"
      spacing={2}
      sx={{ userSelect: "none", px: 1.75, py: 1, mt: 1 }}
    >
      <Grid item xs={12} maxWidth="300px" textAlign="center">
        <SoftTypography variant="h5" component="h1">
          ลงชื่อเข้าใช้
        </SoftTypography>
      </Grid>
      <Grid item xs={12} maxWidth="300px">
        <SoftInput
          placeholder="อีเมล"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} maxWidth="300px">
        <SoftInput
          placeholder="รหัสผ่าน"
          type={showPass ? "input" : "password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Grid>
      <Grid container item xs={12} maxWidth="300px" columnSpacing={2}>
        <Grid item>
          <Switch
            color="default"
            checked={showPass}
            onChange={(e) => {
              setShowPass(!showPass);
            }}
          />
        </Grid>
        <Grid item>
          <SoftTypography variant="button">
            {showPass ? "แสดงรหัสผ่าน" : "ซ่อนรหัสผ่าน"}
          </SoftTypography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vEmail ? "success" : "error"}>
            {vEmail ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vEmail ? "dark" : "error"}
          >{`โปรดระบุอีเมล`}</SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item xs={12}>
        <SoftBox display="flex" flexDirection="row" alignItems="center">
          <Icon color={vPassword ? "success" : "error"}>
            {vPassword ? "check_circle" : "cancel"}
          </Icon>
          <SoftTypography
            ml={1}
            variant="caption"
            component="p"
            color={vPassword ? "dark" : "error"}
          >{`โปรดระบุรหัสผ่าน`}</SoftTypography>
        </SoftBox>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item>
          <SoftButton
            size="small"
            color="primary"
            variant="gradient"
            disabled={!(vEmail && vPassword)}
            onClick={submitSignIn}
          >
            ลงชื่อเข้าใช้
          </SoftButton>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" columnSpacing={4}>
        <Grid item>
          <NavLink to={FORGET_PASSWORD_PATH}>
            <SoftTypography variant="caption">ลืมรหัสผ่าน</SoftTypography>
          </NavLink>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <NavLink to={SIGN_UP_PATH}>
            <SoftTypography variant="caption">สมัครสมาชิก</SoftTypography>
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignInForm;
