import { SIGN_IN, SIGN_OUT } from "actions/types";

export const signIn = (fbData) => {
  return {
    type: SIGN_IN,
    payload: {
      user: {
        uid: fbData.uid,
        email: fbData.email,
        fbData,
        token: fbData.accessToken,
        tokenExpireAt: fbData.stsTokenManager.expirationTime,
      },
    },
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};
