import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SoftBadge from "softcomponents/SoftBadge";

const SortQuestionPageBar = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeMethod, setActiveMethod] = useState("recent_ask");

  useEffect(() => {
    const sortBy = searchParams.get("sort_by");
    if (sortBy) {
      if (
        [
          "recent_ask",
          "recent_answer",
          "vote",
          "answer_count",
          "view",
        ].includes(sortBy) &
        (searchParams.get("sort_desc") == "true")
      ) {
        setActiveMethod(sortBy);
      } else {
        setActiveMethod("recent_ask");
      }
    } else {
      setActiveMethod("recent_ask");
    }
  }, [searchParams]);

  const SortFilterBox = ({ text, method }) => {
    return (
      <Grid item>
        <SoftBadge
          color={method === activeMethod ? "dark" : "light"}
          badgeContent={text}
          sx={{
            cursor: method === activeMethod ? "default" : "pointer",
            userSelect: "none",
          }}
          onClick={() => {
            if (method !== activeMethod) {
              setSearchParams({
                ...Object.fromEntries([...searchParams]),
                sort_by: method,
                sort_desc: true,
              });
            }
          }}
        />
      </Grid>
    );
  };

  return (
    <Grid container p={1} justifyContent="flex-end" spacing={0}>
      <SortFilterBox text="ใหม่ล่าสุด" method="recent_ask" />
      <SortFilterBox text="ตอบล่าสุด" method="recent_answer" />
      <SortFilterBox text="ตอบมากสุด" method="answer_count" />
      <SortFilterBox text="คะแนนสูงสุด" method="vote" />
      <SortFilterBox text="ยอดชมสูงสุด" method="view" />
    </Grid>
  );
};

export default SortQuestionPageBar;
