import Container from "@mui/material/Container";
import { Fragment } from "react";
import Card from "@mui/material/Card";

import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";

import { useEffect } from "react";

const CookiesPolicy = () => {
  useEffect(() => {
    setTimeout(() => {
      document.title = "นโยบายคุกกี้ | หารือ.คอม";
    }, 100);
  }, []);

  return (
    <Fragment>
      <SoftBox component="section" pt={3} pb={0}>
        <Container>
          <Card>
            <SoftBox
              variant="gradient"
              bgColor="dark"
              borderRadius="xl"
              coloredShadow="dark"
              p={3}
              mt={-3}
              mx={2}
            >
              <SoftBox pt={3}>
                <SoftTypography variant="h4" fontWeight="bold" color="white">
                  {"นโยบายคุกกี้"}
                </SoftTypography>
                <SoftTypography variant="caption" color="white" opacity={0.8}>
                  {"แก้ไขล่าสุด"}:{" "}
                  {new Date(Date.parse("18 Feb 2023")).toLocaleDateString()}
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={6} px={6}>
              <SoftTypography variant="h5" mt={6} mb={3}>
                {"คุกกี้คืออะไร?"}
              </SoftTypography>
              <SoftTypography variant="body2" color="text">
                {
                  "คุกกี้ คือ ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังอุปกรณ์คอมพิวเตอร์หรืออุปกรณ์มือถือของท่าน ซึ่งทำหน้าที่บันทึกข้อมูลและการตั้งค่าต่าง ๆ เช่น บันทึกข้อมูลการตั้งค่าภาษาในอุปกรณ์บนอุปกรณ์ของท่าน บันทึกสถานะการเข้าใช้งานในปัจจุบันของท่าน เพื่อช่วยให้ท่านสามารถเข้าใช้งานเว็บไซต์และ/หรือแอปพลิเคชัน (รวมเรียกว่า “บริการ”) ได้อย่างต่อเนื่อง รวมถึงมีการรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าใช้งานบริการที่ท่านชื่นชอบในรูปแบบไฟล์ โดยคุกกี้ไม่ได้ทำให้เกิดอันตรายต่ออุปกรณ์ของท่าน และเนื้อหาในคุกกี้จะถูกเรียกออกมาดูหรืออ่านได้โดยบริการที่สร้างคุกกี้ดังกล่าวเท่านั้น"
                }
              </SoftTypography>
              <SoftTypography variant="h5" mt={6} mb={3}>
                {"ประโยชน์ของคุกกี้"}
              </SoftTypography>
              <SoftTypography variant="body2" color="text">
                {
                  "คุกกี้จะบอกให้ หารือ.คอม ทราบว่าท่านเข้าใช้งานส่วนใดในบริการของ หารือ.คอม เพื่อที่ หารือ.คอม จะสามารถมอบประสบการณ์การใช้บริการที่ดีขึ้นและตรงกับความต้องการของท่านได้ นอกจากนี้ การบันทึกการตั้งค่าแรกของบริการด้วยคุกกี้จะช่วยให้ท่านเข้าถึงบริการด้วยค่าที่ตั้งไว้ทุกครั้งที่ใช้งาน ยกเว้นในกรณีที่คุกกี้ถูกลบซึ่งจะทำให้การตั้งค่าทุกอย่างจะกลับไปที่ค่าเริ่มต้น"
                }
              </SoftTypography>
              <SoftTypography variant="h5" mt={6} mb={3}>
                {"การใช้งานคุกกี้"}
              </SoftTypography>
              <SoftTypography variant="body2" color="text" fontWeight="regular">
                {
                  "หารือ.คอม ใช้คุกกี้และเทคโนโลยีอื่น เช่น โลคอล สโตร์เรจ (Local Storage) บนบริการของ หารือ.คอม (รวมเรียกว่า “บริการที่อ้างอิงนโยบายการใช้คุกกี้”) ดังนั้น บริการใดก็ตามที่ท่านใช้เพื่อเข้าถึงบริการที่อ้างอิงนโยบายการใช้คุกกี้จะได้รับคุกกี้จาก หารือ.คอม"
                }
                <br />
                <br />
                {
                  "เมื่อท่านเข้าถึงบริการที่อ้างอิงนโยบายการใช้คุกกี้ คุกกี้ของ หารือ.คอม จะถูกดาวน์โหลดไปยังอุปกรณ์ของท่านเพื่อเก็บข้อมูลเกี่ยวกับรูปแบบการใช้และประวัติการใช้บริการ ข้อมูลหรือบริการที่ท่านสนใจ รวมถึงหมายเลขอ้างอิงการเข้าใช้บริการล่าสุดของท่าน เป็นต้น"
                }
                <br />
                <br />
                {
                  "นอกจากนั้น หารือ.คอม มีการใช้งานคุกกี้ร่วมกับเทคโนโลยีอื่น เพื่อทราบถึงรูปแบบการใช้และประวัติการใช้บริการ ข้อมูลหรือบริการที่ท่านสนใจ และนำไปวิเคราะห์ เพื่อพัฒนาการให้บริการ แสดงเนื้อหา โฆษณา หรือประชาสัมพันธ์กิจกรรมที่เหมาะสมรวมถึงการบริการต่าง ๆ ที่ตรงกับความสนใจของท่าน เพื่อเพิ่มความพึงพอใจให้แก่ท่านได้มากยิ่งขึ้น"
                }
                <br />
                <br />
                {
                  "อย่างไรก็ตาม หารือ.คอม จะใช้งานคุกกี้ภายใต้รายละเอียดที่ระบุในคำประกาศเกี่ยวกับความเป็นส่วนตัว ซึ่งท่านสามารถศึกษาข้อมูลเพิ่มเติมได้จาก https://www.ha-rue.com/privacy"
                }
                <br />
                <br />
                {
                  "หารือ.คอม อาจมีการใช้งานโปรแกรมเสริม (Plugin) และบริการของบุคคลที่สาม เช่น Google Analytics"
                }
              </SoftTypography>
              <SoftTypography variant="h5" mt={6} mb={3}>
                {"ประเภทของคุกกี้"}
              </SoftTypography>
              <SoftTypography variant="h6" mb={1}>
                {"คุกกี้จำเป็นอย่างยิ่ง"}
              </SoftTypography>
              <SoftTypography variant="body2" color="text">
                {
                  "คุกกี้เหล่านี้จำเป็นต่อเว็บไซต์ของเราเพื่อให้เว็บไซต์ทำงานได้ และไม่สามารถปิดหรือไม่อนุญาตให้ใช้ได้"
                }
              </SoftTypography>
              <SoftTypography variant="h6" mt={3} mb={1}>
                {"คุกกี้ทางเลือก"}
              </SoftTypography>
              <SoftTypography variant="body2" color="text">
                {
                  "เราจะร้องขออนุญาตคุณเพื่อที่จะใช้งานคุกกี้ข้างล่างบนอุปกรณ์ของคุณ ทั้งนี้หากคุณเลือกที่จะปิดการใช้งาน ฟังก์ชั่นบางอย่างอาจทำงานไม่ถูกต้อง เราจะใช้คุกกี้เหล่านี้เมื่อได้รับอนุญาตจากคุณเท่านั้น ทั้งนี้เราได้อธิบายเพิ่มเติมเกี่ยวกับรายละเอียดของคุกกี้ทางเลือกดังนี้"
                }
              </SoftTypography>
              <SoftTypography variant="h6" mt={3} mb={1}>
                {"คุกกี้ประสิทธิภาพ"}
              </SoftTypography>
              <SoftTypography variant="body2" color="text">
                {
                  "คุกกี้ประเภทนี้จะช่วยให้เรานับจำนวนการเข้าชม ซึ่งช่วยให้เราสามารถวัดผลและปรับปรุงประสิทธิภาพของเว็บไซต์ คุกกี้เหล่านี้ช่วยให้เรารู้ได้ว่าหน้าใดที่ได้รับความนิยมมากหรือน้อยที่สุดและเห็นว่าผู้เยี่ยมชมใช้งานเว็บไซต์อย่างไร ซึ่งเราจะนำข้อมูลเหล่านี้ไปพัฒนาประสบการณ์ที่ดียิ่งขึ้นให้แก่ท่าน ข้อมูลทั้งหมดที่คุกกี้เหล่านี้รวบรวมจะเป็นแบบไม่ระบุตัวจน ถ้าหากคุณไม่อนุญาตให้ใช้คุกกี้เหล่านี้ เราจะไม่สามารถใช้ข้อมูลของคุณตามที่ระบุได้"
                }
              </SoftTypography>
              <SoftTypography variant="h5" mt={6} mb={3}>
                {"วิธีปิดการทำงานของคุกกี้"}
              </SoftTypography>
              <SoftTypography variant="body2" color="text">
                {
                  "ท่านสามารถปิดการทำงานของคุกกี้ได้ โดยการตั้งค่าเบราว์เซอร์ของท่าน และตั้งค่าความเป็นส่วนตัวเพื่อระงับการรวบรวมข้อมูลโดยคุกกี้ในอนาคต (รายละเอียดเพิ่มเติมสามารถศึกษาได้จาก AboutCookies.org)"
                }
                <br />
                <br />
                {
                  "อย่างไรก็ตาม บริการบางอย่างบนเว็บไซต์ของ หารือ.คอม จำเป็นต้องมีการใช้คุกกี้ หากท่านปิดการทำงานคุกกี้อาจทำให้ท่านใช้งานฟังก์ชันบางอย่างหรือทั้งหมดของบริการดังกล่าวได้อย่างไม่ราบรื่น"
                }
              </SoftTypography>
            </SoftBox>
          </Card>
        </Container>
      </SoftBox>
    </Fragment>
  );
};

export default CookiesPolicy;
