import { useState } from "react";
import SoftBadge from "softcomponents/SoftBadge";
import { useNavigate } from "react-router-dom";

const QuestionTag = ({ tag }) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  return (
    <SoftBadge
      badgeContent={tag}
      variant="gradient"
      color={hover ? "info" : "dark"}
      size={hover ? "lg" : "sm"}
      circular
      style={{ cursor: "pointer", userSelect: "none" }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        navigate(`/questions?tags=${tag}`);
      }}
    />
  );
};

export default QuestionTag;
