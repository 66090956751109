import store from "store";

import { deploySnackbar } from "actions";

export const deployErrorSnackbar = (error) => {
  const dispatch = store.dispatch;

  const snackBar = {
    color: "error",
    icon: "report_problem",
    title: error.response.data.message.code,
    content: error.response.data.message.text,
  };
  dispatch(deploySnackbar(snackBar));
};

export const apiServerError = () => (dispatch, getState) => {
  const snackBar = {
    color: "error",
    icon: "report_problem",
    title: "Unable to connect API server",
    content: `Please check your internet connection.`,
  };
  dispatch(deploySnackbar(snackBar));
};
