import { Grid } from "@mui/material";

import MainLayout from "layouts/MainLayout";

import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";

const ForgetPassword = () => {
  return (
    <MainLayout size="sm">
      <SoftBox mt={3} sx={{ userSelect: "none" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SoftTypography variant="h5" component="h1">
              ลืมรหัสผ่าน
            </SoftTypography>
          </Grid>
          <Grid item xs={12}>
            <SoftTypography variant="caption" component="p">
              เนื่องจากเวปไซต์อยู่ในระหว่างการทดสอบ
              หากลืมรหัสผ่านกรุณาส่งอีเมลที่ลงทะเบียนแจ้ง admin@ha-rue.com
            </SoftTypography>
          </Grid>
        </Grid>
      </SoftBox>
    </MainLayout>
  );
};

export default ForgetPassword;
