import { auth } from "./config";

import { signOut as fbSignOut } from "firebase/auth";

const signOut = () => {
  fbSignOut(auth)
    .then(() => {
    })
    .catch((error) => {
    });

  return;
};

export default signOut;
