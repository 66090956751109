import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";
import Divider from "@mui/material/Divider";
import QuestionTag from "components/QuestionTag";
import QuestionScenario from "components/QuestionScenario";

import QuestionBoxCaption from "./caption";
import CreateBy from "components/CreateBy";

const QuestionBox = ({ question }) => {
  const [hoverTitle, setHoverTitle] = useState(false);

  const navigate = useNavigate();

  return (
    <Fragment>
      <Divider variant="none" sx={{ m: 1 }} />
      <SoftBox
        sx={{ px: 2.5, py: 1, userSelect: "none" }}
        display="flex"
        flexDirection="column"
      >
        <SoftBox display="flex" sx={{ flexGrow: 1 }}>
          <SoftBox
            sx={{ pt: 0.5, pr: 2, minWidth: "85px" }}
            display="flex"
            flexDirection="column"
            textAlign="right"
          >
            <SoftTypography variant="caption" style={{ lineHeight: "1.25rem" }}>
              {question.vote_net.toLocaleString()} โหวต
            </SoftTypography>
            <SoftTypography variant="caption" style={{ lineHeight: "1.25rem" }}>
              {question.answer_count.toLocaleString()} คำตอบ
            </SoftTypography>
            <SoftTypography variant="caption" style={{ lineHeight: "1.25rem" }}>
              {question.view_count.toLocaleString()} เข้าชม
            </SoftTypography>
          </SoftBox>
          <SoftBox sx={{ flexGrow: 1 }}>
            <SoftBox>
              <SoftTypography
                variant="h6"
                component="h1"
                style={{
                  lineHeight: "1.25rem",
                  cursor: "pointer",
                }}
                onMouseEnter={() => {
                  setHoverTitle(true);
                }}
                onMouseLeave={() => {
                  setHoverTitle(false);
                }}
                onClick={() => {
                  navigate(`/question/${question.post_code}`);
                }}
                fontWeight={hoverTitle ? "bold" : false}
              >
                {question.title}
              </SoftTypography>
            </SoftBox>
            <QuestionBoxCaption body={question.caption} />
            <SoftBox lineHeight="1.5rem">
              {question.tags &&
                question.tags.map((t) => {
                  return <QuestionTag key={question.post_code + t} tag={t} />;
                })}
              {question.scenarios &&
                question.scenarios.map((scene) => {
                  return (
                    <QuestionScenario
                      scenario={scene}
                      key={question.post_code + scene.key + scene.value}
                    />
                  );
                })}
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <CreateBy
          display_name={question.created_display_name}
          created_at={question.created_at}
        />
      </SoftBox>
    </Fragment>
  );
};

export default QuestionBox;
