function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export default function stringAvatar(name) {
  const splitName = name.split(" ");
  let child = name[0].toUpperCase();
  if (splitName.length === 2) {
    child = `${splitName[0][0]}${splitName[1][0]}`.toUpperCase();
  } else if (splitName.length === 3) {
    child =
      `${splitName[0][0]}${splitName[1][0]}${splitName[2][0]}`.toUpperCase();
  } else if (splitName.length > 3) {
    child = `${splitName[0][0]}${
      splitName[splitName.length - 1][0]
    }`.toUpperCase();
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: child,
  };
}
