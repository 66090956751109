import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { SoftUIControllerProvider } from "context";

import { Provider } from "react-redux";
import store from "store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SoftUIControllerProvider>
        <CssBaseline />
        <App />
      </SoftUIControllerProvider>
    </ThemeProvider>
  </Provider>
);
