import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const FullPageLoading = () => {
  return (
    <>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backdropFilter: "blur(5px)",
          }}
          open={true}
        >
          <CircularProgress color="inherit" thickness={6} />
        </Backdrop>
    </>
  );
};

export default FullPageLoading;
