import { useState, useEffect } from "react";

import SoftBox from "softcomponents/SoftBox";
import { Grid } from "@mui/material";
import SoftTypography from "softcomponents/SoftTypography";
import SoftAvatar from "softcomponents/SoftAvatar";

import stringAvatar from "app/utils/stringAvatar";

const CreateBy = ({ display_name, created_at }) => {
  const [askWhen, setAskWhen] = useState("...");

  const updateAskWhen = (timestamp) => {
    const now = new Date();
    const then = new Date(timestamp);
    const diff = now - then;
    const diffInMinutes = Math.floor(diff / 1000 / 60);
    const diffInHours = Math.floor(diff / 1000 / 60 / 60);
    const diffInDays = Math.floor(diff / 1000 / 60 / 60 / 24);
    const diffInMonths = Math.floor(diff / 1000 / 60 / 60 / 24 / 30);
    const diffInYears = Math.floor(diff / 1000 / 60 / 60 / 24 / 365);

    let next = 1000 * 60 * 60 * 24;

    if (diffInYears > 0) {
      setAskWhen(`${diffInYears} ปีที่แล้ว`);
    } else if (diffInMonths > 0) {
      setAskWhen(`${diffInMonths} เดือนที่แล้ว`);
    } else if (diffInDays > 0) {
      setAskWhen(`${diffInDays} วันที่แล้ว`);
    } else if (diffInHours > 0) {
      setAskWhen(`${diffInHours} ชั่วโมงที่แล้ว`);
      next = (diffInHours + 1) * 1000 * 60 * 60 - diff;
    } else if (diffInMinutes > 0) {
      setAskWhen(`${diffInMinutes} นาทีที่แล้ว`);
      next = (diffInMinutes + 1) * 1000 * 60 - diff;
    } else {
      setAskWhen("เมื่อสักครู่");
      next = 60 * 1000 - diff;
    }

    if (next < 1000 * 60 * 60 * 24) {
      setTimeout(() => {
        updateAskWhen(timestamp);
      }, next);
    }
  };

  useEffect(() => {
    if (created_at) {
      updateAskWhen(created_at);
    }
  }, [created_at]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing={1}
      mt={{ xs: 1, sm: 0.75 }}
    >
      <Grid item>
        <SoftBox display="flex" flexDirection="column" textAlign="right">
          <SoftTypography variant="caption" style={{ lineHeight: "1.25rem" }}>
            {display_name}
          </SoftTypography>
          <SoftTypography variant="caption" style={{ lineHeight: "1.25rem" }}>
            เมื่อ {askWhen}
          </SoftTypography>
        </SoftBox>
      </Grid>
      <Grid item>
        <SoftBox display="flex" flexDirection="column" textAlign="right">
          <SoftAvatar
            {...stringAvatar(display_name)}
            variant="rounded"
            size="sm"
          />
        </SoftBox>
      </Grid>
    </Grid>
  );
};

export default CreateBy;
