import { Grid } from "@mui/material";

const MainLayout = ({ size = "md", children }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item maxWidth={size} width="100%">
        {children}
      </Grid>
    </Grid>
  );
};

export default MainLayout;
