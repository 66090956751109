import { useEffect, useState } from "react";
import { Grid, Divider, Icon } from "@mui/material";
import { connect } from "react-redux";
import SoftBox from "softcomponents/SoftBox";
import SoftInput from "softcomponents/SoftInput";
import SoftTypography from "softcomponents/SoftTypography";
import SoftButton from "softcomponents/SoftButton";
import UserAPI from "app/api/user";
import { deployErrorSnackbar } from "actions/snackBar";

const AnswerForm = ({ isSignIn, post_code }) => {
  const [body, setBody] = useState("");
  const [verify, setVerify] = useState(false);

  useEffect(() => {
    if (body.length >= 20) {
      setVerify(true);
    } else {
      setVerify(false);
    }
  }, [body]);

  const restartForm = () => {
    setBody("");
  };

  const submitAnswer = async () => {
    const data = { body };
    try {
      const result = await UserAPI().post(
        `/questions/${post_code}/answer`,
        data
      );
      window.location.reload();
    } catch (error) {
      deployErrorSnackbar(error);
    }
  };

  return (
    <SoftBox width="100%" sx={{ px: 1.75, py: 1, mt: 1 }}>
      {isSignIn ? (
        <SoftBox mb={2}>
          <SoftInput
            placeholder="โปรดระบุคำตอบ"
            multiline
            rows={4}
            value={body}
            onChange={(e) => {
              setBody(e.target.value);
            }}
          />
          <SoftBox mt={1}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              rowSpacing={1}
            >
              {body.length > 0 && (
                <Grid item xs={12}>
                  <SoftBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Icon color={verify ? "success" : "error"}>
                      {verify ? "check_circle" : "cancel"}
                    </Icon>
                    <SoftTypography
                      ml={1}
                      variant="caption"
                      component="p"
                      color={verify ? "dark" : "error"}
                    >{`เนื้อหาต้องมีความยาวอย่างน้อย 20 ตัวอักษร (ปัจจุบัน ${body.length} อักษร)`}</SoftTypography>
                  </SoftBox>
                </Grid>
              )}
              <Grid item>
                <SoftButton
                  variant="gradient"
                  color="warning"
                  size="small"
                  onClick={restartForm}
                >
                  <Icon>restart_alt</Icon>&nbsp;รีเซ็ต
                </SoftButton>
              </Grid>
              <Grid item>
                <SoftButton
                  variant="gradient"
                  color="primary"
                  size="small"
                  onClick={submitAnswer}
                  disabled={!verify}
                >
                  <Icon>send</Icon>&nbsp;ส่งคำตอบ
                </SoftButton>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      ) : (
        <SoftBox width="100%" mb={2} textAlign="center">
          <SoftTypography variant="caption" sx={{ userSelect: "none" }}>
            โปรดลงชื่อเข้าใช้เพื่อตอบคำถามนี้
          </SoftTypography>
        </SoftBox>
      )}
      <Divider />
    </SoftBox>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignIn: state.app.isSignIn,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerForm);
