import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import QuestionListBox from "components/QuestionsListBox";
import UserAPI from "app/api/user";

import SortQuestionPageBar from "components/SortQuestionPageBar";
import { updateQuestionSearchParams } from "actions";
import checkObjectEqual from "app/utils/checkObjectEqual";
import Pagination from "components/Pagination";

import MainLayout from "layouts/MainLayout";

const ListQuestionPage = ({ saveSearchParams, updateSearchParams }) => {
  const [questions, setQuestions] = useState([]);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const reload = async (params) => {
    const result = await UserAPI().get("/questions/", {
      params,
    });
    setQuestionsCount(result.data.data.count);
    setQuestions(result.data.data.questions);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const parse_params = Object.fromEntries([...searchParams]);

    var params = { ...parse_params };
    if (searchParams.get("clear") === 1) {
      delete params.clear;
      setSearchParams(params);
      return;
    } else if (Object.keys(parse_params).length > 0) {
      const combineParams = { ...saveSearchParams, ...params };
      if (checkObjectEqual(params, combineParams)) {
        updateSearchParams(combineParams);
        reload(params);
      } else {
        updateSearchParams(combineParams);
        setSearchParams(combineParams);
      }
    } else {
      reload({});
    }
  }, [searchParams]);

  return (
    <MainLayout>
      <SortQuestionPageBar />
      {questions.map((q) => {
        return <QuestionListBox question={q} key={q.post_code} />;
      })}
      <Pagination count={questionsCount} />
    </MainLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    saveSearchParams: state.app.questionSearchParams,
  };
};

const mapDispatchToProps = {
  updateSearchParams: updateQuestionSearchParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListQuestionPage);
