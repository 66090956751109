import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SoftBadge from "softcomponents/SoftBadge";

const SortAnswerPageBar = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeMethod, setActiveMethod] = useState("vote");

  useEffect(() => {
    const sortBy = searchParams.get("sort_by");
    if (sortBy) {
      if (
        ["recent", "vote"].includes(sortBy) &
        (searchParams.get("sort_desc") == "true")
      ) {
        setActiveMethod(sortBy);
      } else {
        setActiveMethod("recent");
      }
    } else {
      setActiveMethod("vote");
    }
  }, [searchParams]);

  const SortFilterBox = ({ text, method }) => {
    return (
      <Grid item>
        <SoftBadge
          color={method === activeMethod ? "dark" : "light"}
          badgeContent={text}
          sx={{
            cursor: method === activeMethod ? "default" : "pointer",
            userSelect: "none",
          }}
          onClick={() => {
            if (method !== activeMethod) {
              setSearchParams({
                ...Object.fromEntries([...searchParams]),
                sort_by: method,
                sort_desc: true,
              });
            }
          }}
        />
      </Grid>
    );
  };

  return (
    <Grid container p={1} justifyContent="flex-end" spacing={0}>
      <SortFilterBox text="คะแนนสูงสุด" method="vote" />
      <SortFilterBox text="ตอบล่าสุด" method="recent" />
    </Grid>
  );
};

export default SortAnswerPageBar;
