import { Icon, Tooltip } from "@mui/material";
import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";
import UserAPI from "app/api/user";

import { deployErrorSnackbar } from "actions/snackBar";
import { connect } from "react-redux";

const VoteButton = ({ vote = 0, post_code = "", user_vote, isSignIn }) => {
  const voteUp = async () => {
    try {
      const result = await UserAPI().post(`/posts/${post_code}/vote`, {
        score: 1,
      });
      window.location.reload();
    } catch (error) {
      deployErrorSnackbar(error);
    }
  };

  const voteDown = async () => {
    try {
      const result = await UserAPI().post(`/posts/${post_code}/vote`, {
        score: -1,
      });
      window.location.reload();
    } catch (error) {
      deployErrorSnackbar(error);
    }
  };

  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      sx={{
        pr: 1,
        minWidth: "40px",
        userSelect: "none",
        alignItems: "center",
        lineHeight: 0,
      }}
    >
      {!isSignIn || user_vote === 1 ? (
        <SoftBox>
          <Tooltip
            title={
              user_vote === 1
                ? "คุณได้โหวตให้คะแนนนี้แล้ว"
                : "โปรดลงชื่อเข้าใช้เพื่อโหวต"
            }
            placement="right-start"
          >
            <SoftTypography
              variant="h5"
              component="span"
              lineHeight={0}
              color="secondary"
            >
              <Icon>expand_less</Icon>
            </SoftTypography>
          </Tooltip>
        </SoftBox>
      ) : (
        <SoftBox sx={{ cursor: "pointer" }} onClick={voteUp}>
          <SoftTypography variant="h5" component="span" lineHeight={0}>
            <Icon>expand_less</Icon>
          </SoftTypography>
        </SoftBox>
      )}

      <SoftBox my={1.5}>
        <SoftTypography variant="h6" component="span" lineHeight={0}>
          {`${vote.toLocaleString()}`}
        </SoftTypography>
      </SoftBox>
      {!isSignIn || user_vote === -1 ? (
        <SoftBox>
          <Tooltip
            title={
              user_vote === -1
                ? "คุณได้โหวตให้คะแนนนี้แล้ว"
                : "โปรดลงชื่อเข้าใช้เพื่อโหวต"
            }
            placement="right-start"
          >
            <SoftTypography
              variant="h5"
              component="span"
              lineHeight={0}
              color="secondary"
            >
              <Icon>expand_more</Icon>
            </SoftTypography>
          </Tooltip>
        </SoftBox>
      ) : (
        <SoftBox sx={{ cursor: "pointer" }} onClick={voteDown}>
          <SoftTypography variant="h5" component="span" lineHeight={0}>
            <Icon>expand_more</Icon>
          </SoftTypography>
        </SoftBox>
      )}
    </SoftBox>
  );
};

const mapStateToProps = (state) => {
  return { isSignIn: state.app.isSignIn };
};

export default connect(mapStateToProps)(VoteButton);
