import { useState, useEffect } from "react";

import getConsent from "app/utils/consent/getConsent";
import checkObjectEqual from "app/utils/checkObjectEqual";

const useConsent = () => {
  const [consent, setConsent] = useState({});

  useEffect(() => {
    setConsent(getConsent());

    const observer = () => {
      const get = getConsent();
      if (!checkObjectEqual(get, consent)) {
        setConsent(getConsent());
      }
    };

    window.addEventListener("click", observer);

    return () => window.removeEventListener("click", observer);
  }, []);

  return [consent];
};

export default useConsent;
