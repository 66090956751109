import { useEffect, useState } from "react";

import SoftTagInput from "softcomponents/SoftTagInput";

const ForumScenarioInput = ({ scenarios, setScenarios }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(scenarios.map((scene) => `${scene.key} # ${scene.value}`));
  }, [scenarios]);

  const addScenario = (scene) => {
    let newTags = scene.map((item) => {
      if (item.includes("#")) {
        const splitItem = item.split("#");
        return `${splitItem.at(0).trim()} # ${splitItem.at(1).trim()}`;
      } else {
        return `อื่นๆ#${item}`;
      }
    });

    newTags = [...new Set(newTags)];

    setScenarios(
      newTags.map((tag) => {
        const splitItem = tag.split("#");
        return { key: splitItem.at(0).trim(), value: splitItem.at(1).trim() };
      })
    );
  };

  return (
    <SoftTagInput
      placeholder="เพิ่มสถานการณ์ เช่น ผู้ขาย#บุคคลธรรมดา"
      tags={tags}
      onChange={addScenario}
    />
  );
};

export default ForumScenarioInput;
