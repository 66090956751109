import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Divider } from "@mui/material";

import colors from "assets/theme/base/colors";
import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";

import AppBarSearchInput from "./SearchInput";
import AskQuestionButton from "components/AskQuestionButton";
import SignInButton from "./SignInButton";
import { connect } from "react-redux";
import UserMenu from "./UserMenu";

const AppBar = ({ isSignIn }) => {
  const parentRef = useRef(null);
  const childRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    childRef.current.style.height = `${parentRef.current.offsetHeight}px`;
  }, []);

  return (
    <SoftBox sx={{ flexGrow: 1 }}>
      <MuiAppBar
        position="fixed"
        sx={{ backgroundColor: colors.background.default }}
        ref={parentRef}
      >
        <Toolbar variant="dense">
          <SoftTypography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer", userSelect: "none" }}
            onClick={() => {
              navigate("/");
            }}
          >
            หารือ.คอม
          </SoftTypography>

          <SoftBox
            sx={{ flexGrow: 1 }}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <SoftBox width={{ lg: "400px" }} mr={{ xs: 1.5, md: 1.5, lg: 2 }}>
              <AppBarSearchInput />
            </SoftBox>
            <SoftBox mr={!isSignIn ? { xs: 0, md: 1.5, lg: 2 } : 0}>
              <AskQuestionButton />
            </SoftBox>
            {isSignIn ? <UserMenu /> : <SignInButton />}
          </SoftBox>
        </Toolbar>
        <Divider />
      </MuiAppBar>
      <SoftBox ref={childRef} />
    </SoftBox>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignIn: state.app.isSignIn,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
