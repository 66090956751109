import { Divider } from "@mui/material";
import AnswerForm from "components/AnswerForm";
import Pagination from "components/Pagination";
import SortAnswerPageBar from "components/SortAnswerPageBar";
import { useState, useEffect, Fragment } from "react";
import SoftBox from "softcomponents/SoftBox";
import SoftTypography from "softcomponents/SoftTypography";
import PostBox from "./PostBox";

const QuestionAnswerTemplate = ({ data }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (data.result) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    try {
      document.title = `${data.result.question.title} | หารือ.คอม`;
    } catch {
      document.title = `หารือ.คอม`;
    }
  }, [data]);

  return (
    <SoftBox display="flex" flexDirection="column">
      {isValid ? (
        <Fragment>
          <PostBox post={data.result.question} />
          <AnswerForm post_code={data.result.question.post_code} />
          <SortAnswerPageBar />
          {data.result.answers &&
            data.result.answers.map((answer) => {
              return <PostBox post={answer} key={answer.post_code} />;
            })}
          <Pagination count={data.result.question.answer_count} />
        </Fragment>
      ) : (
        <SoftBox width="100%" textAlign="center" py={5}>
          loading...
        </SoftBox>
      )}
    </SoftBox>
  );
};

export default QuestionAnswerTemplate;
