import { connect } from "react-redux";

import { Grid } from "@mui/material";

import SoftBox from "softcomponents/SoftBox";
import Snackbar from "components/Snackbars/Snackbar";
import { Fragment } from "react";

const Snackbars = ({ snackBars }) => {
  return (
    <Fragment>
      {snackBars.length > 0 && (
        <SoftBox
          sx={{
            position: "fixed",
            bottom: "0px",
            right: "0px",
            zIndex: 100
          }}
        >
          <SoftBox p={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              spacing={2}
            >
              {snackBars.map((snackBar) => {
                return (
                  <Grid item key={snackBar.uuid}>
                    <Snackbar
                      color={snackBar.color}
                      icon={snackBar.icon}
                      title={snackBar.title}
                      content={snackBar.content}
                      timestamp={snackBar.timestamp}
                      timeout={snackBar.timeout}
                      uuid={snackBar.uuid}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </SoftBox>
        </SoftBox>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    snackBars: state.app.snackBars,
  };
};

export default connect(mapStateToProps)(Snackbars);
