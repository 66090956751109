import { auth } from "./config";

import { signInWithEmailAndPassword } from "firebase/auth";

const signIn = async (email, password) => {
  let result = {};

  await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      result = { type: "success", data: { ...userCredential } };
    })
    .catch((error) => {
      result = { type: "error", data: { ...error } };
    });

  return result;
};

export default signIn;
