import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

import UserAPI from "app/api/user";

import {
  UPDATE_CSRF_TOKEN,
  DEPLOY_SNACKBAR,
  UPDATE_SNACKBAR,
  UPDATE_QUESTION_SEARCH_PARAMS,
  UPDATE_ANSWER_SEARCH_PARAMS,
} from "actions/types";

import { apiServerError } from "actions/snackBar";

import { CSRF_COOKIE } from "settings/cookies";

export const getCsrfToken = () => async (dispatch, getState) => {
  try {
    const response = await UserAPI().get("/getcsrftoken");
    Cookies.set(CSRF_COOKIE, response.data.data.csrfToken);
    dispatch({
      type: UPDATE_CSRF_TOKEN,
      payload: response.data.data,
    });
  } catch (error) {
    if (!error.response) {
      dispatch(apiServerError());
    }
  }
};

export const getCsrfTokenFromCookies = () => (dispatch, getState) => {
  const csrfToken = Cookies.get(CSRF_COOKIE);
  if (csrfToken) {
    dispatch({
      type: UPDATE_CSRF_TOKEN,
      payload: csrfToken,
    });
  }
};

export const deploySnackbar = ({
  color = "info",
  icon = "notifications",
  title = "",
  content = "",
  timeout = 1000 * 10,
}) => {
  const snackBar = {
    color,
    icon,
    title,
    content,
    timestamp: Date.now(),
    timeout,
    uuid: uuidv4(),
  };
  return {
    type: DEPLOY_SNACKBAR,
    payload: { newSnackBar: snackBar },
  };
};

export const destroySnackbar = (uuid) => (dispatch, getState) => {
  const currentSnackBars = getState().app.snackBars.filter(
    (element) => element.uuid !== uuid
  );

  dispatch({
    type: UPDATE_SNACKBAR,
    payload: { newSnackBars: currentSnackBars },
  });
};

export const updateQuestionSearchParams = (params) => {
  const searchParams = {};
  const saveKeys = ["sort_by", "sort_desc", "page_size"];

  Object.entries(params).forEach((k) => {
    if (saveKeys.includes(k.at(0))) {
      searchParams[k.at(0)] = k.at(1);
    }
  });

  return {
    type: UPDATE_QUESTION_SEARCH_PARAMS,
    payload: searchParams,
  };
};

export const updateAnswerSearchParams = (params) => {
  const searchParams = {};
  const saveKeys = ["sort_by", "sort_desc", "page_size"];

  Object.entries(params).forEach((k) => {
    if (saveKeys.includes(k.at(0))) {
      searchParams[k.at(0)] = k.at(1);
    }
  });

  return {
    type: UPDATE_ANSWER_SEARCH_PARAMS,
    payload: searchParams,
  };
};
