import { useEffect, useState } from "react";
import { Grid, Icon } from "@mui/material";
import Divider from "@mui/material/Divider";

import SoftBox from "softcomponents/SoftBox";
import SoftInput from "softcomponents/SoftInput";
import SoftTypography from "softcomponents/SoftTypography";
import SoftButton from "softcomponents/SoftButton";

import ForumTagInput from "components/ForumTagInput";
import MarkDownHtml from "components/MarkDownHtml";
import UserAPI from "app/api/user";
import ForumScenarioInput from "components/ForumScenarioInput";
import AskQuestionVerify from "./verify";
import { useNavigate } from "react-router-dom";

import { deployErrorSnackbar } from "actions/snackBar";

const AskQuestionForm = ({
  initTitle,
  initBody,
  initTags,
  initScenrios,
  ...rest
}) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [tags, setTags] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [verify, setVerify] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTitle(initTitle || "");
  }, [initTitle]);

  useEffect(() => {
    setBody(initBody || "");
  }, [initBody]);

  useEffect(() => {
    setTags(initTags || []);
  }, [initTags]);

  useEffect(() => {
    setScenarios(initScenrios || []);
  }, [initScenrios]);

  const restartForm = () => {
    setTitle(initTitle || "");
    setBody(initBody || "");
    setTags(initTags || []);
    setScenarios(initScenrios || []);
  };

  const submitQuestion = async () => {
    const formatScenarios = scenarios.map((scene) => [scene.key, scene.value]);
    const data = { title, body, tags, scenarios: formatScenarios };
    try {
      const result = await UserAPI().post("/questions/create", data);
      if (result.status == 201) {
        navigate("/");
      }
    } catch (error) {
      deployErrorSnackbar(error);
    }
  };

  return (
    <Grid container rowSpacing={2} {...rest} sx={{ userSelect: "none" }}>
      <Grid container item xs={12} justifyContent="center">
        <Grid item>
          <SoftTypography variant="h5" fontWeight="bold" component="h1">
            ปรึกษาหารือ
          </SoftTypography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SoftInput
          placeholder="ชื่อคำถาม"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SoftInput
          placeholder="โปรดระบุเนื้อหาของคำถาม"
          multiline
          rows={6}
          value={body}
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ForumTagInput tags={tags} setTags={setTags} />
      </Grid>
      <Grid item xs={12}>
        <ForumScenarioInput scenarios={scenarios} setScenarios={setScenarios} />
      </Grid>
      <Grid item xs={12}>
        <AskQuestionVerify title={title} body={body} setVerify={setVerify} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <SoftButton
            variant="gradient"
            color="warning"
            size="small"
            onClick={restartForm}
          >
            <Icon>restart_alt</Icon>&nbsp;รีเซ็ต
          </SoftButton>
        </Grid>

        <Grid item>
          <SoftButton
            variant="gradient"
            color="primary"
            size="small"
            onClick={submitQuestion}
            disabled={!verify}
          >
            <Icon>send</Icon>&nbsp;สร้างคำถาม
          </SoftButton>
        </Grid>
      </Grid>
      <SoftBox width="100%">
        <Divider variant="none" sx={{ my: 2 }} />
        <SoftBox width="100%" textAlign="center" mb={2}>
          <SoftTypography variant="h5" fontWeight="bold">
            ตัวอย่างข้อความที่ปรากฎ
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1.25}>
          <SoftTypography variant="h3">
            {title || "ยังไม่ได้ระบุชื่อคำถาม"}
          </SoftTypography>
        </SoftBox>
        <MarkDownHtml md={body || "ยังไม่ได้ระบุเนื้อหาของคำถาม"} />
        <Divider variant="none" sx={{ my: 2 }} />
      </SoftBox>
    </Grid>
  );
};

export default AskQuestionForm;
