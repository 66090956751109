import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import SoftSelect from "softcomponents/SoftSelect";
import SoftBadge from "softcomponents/SoftBadge";
import SoftBox from "softcomponents/SoftBox";

import { FORUM_TAGS } from "settings/forum";

const ForumTagInput = ({ tags, setTags }) => {
  const [availOptions, setAvailOptions] = useState([]);

  useEffect(() => {
    const opts = FORUM_TAGS.filter((e) => {
      return !tags.includes(e.value);
    });
    setAvailOptions(opts);
  }, [tags]);

  const selectForumTag = (tag) => {
    setTags([...tags, tag]);
  };

  const removeForumTag = (tag) => {
    setTags([...tags].filter((e) => e !== tag));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      columnSpacing={2}
    >
      <Grid item minWidth="300px">
        <SoftSelect
          placeholder="เลือกหัวข้อที่เกี่ยวข้อง..."
          options={availOptions}
          onChange={(e) => {
            selectForumTag(e.value);
          }}
          value={null}
        />
      </Grid>
      <Grid item>
        {tags && (
          <SoftBox>
            {tags.map((t) => {
              return (
                <SoftBadge
                  key={t}
                  badgeContent={`${t} | x`}
                  size="lg"
                  circular
                  onClick={() => {
                    removeForumTag(t);
                  }}
                  style={{ cursor: "pointer", userSelect: "none" }}
                />
              );
            })}
          </SoftBox>
        )}
      </Grid>
    </Grid>
  );
};

export default ForumTagInput;
