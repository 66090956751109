import AskPage from "pages/ask-page";
import SignIn from "pages/sign-in";
import SignOut from "pages/sign-out";
import ForgetPassword from "pages/forget-password";
import SignUp from "pages/sign-up";

import PrivacyPolicy from "pages/legal/privacy-policy";
import CookiesPolicy from "pages/legal/cookies-policy";
import QuestionPage from "pages/question-page";
import ListQuestionPage from "pages/list-question-page";

export const routes = [
  { key: "/ask", path: `/ask`, element: <AskPage /> },
  { key: "/sign-in", path: `/sign-in`, element: <SignIn /> },
  { key: "/sign-up", path: `/sign-up`, element: <SignUp /> },
  { key: "/sign-out", path: `/sign-out`, element: <SignOut /> },
  {
    key: "/forget-password",
    path: `/forget-password`,
    element: <ForgetPassword />,
  },
  {
    key: "/privacy",
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    key: "/privacy/cookies",
    path: "/privacy/cookies",
    element: <CookiesPolicy />,
  },
  { key: "/question/*", path: `/question/*`, element: <QuestionPage /> },
  { key: "/*", path: `/*`, element: <ListQuestionPage /> },
];

export const nonAuthRoutes = [];

export const authRoutes = [];
