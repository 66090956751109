import {
  SIGN_IN,
  SIGN_OUT,
} from "actions/types";

const userDefault = {
  email: "",
  fbData: {},
  token: "",
  tokenExpireAt: "",
  userData: {},
  userSetting: {},
};

const userReducer = (user = userDefault, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...user, ...action.payload.user };
    case SIGN_OUT:
      return { ...userDefault };
    default:
      return user;
  }
};

export default userReducer;
